import React from 'react'
import PropTypes from 'prop-types';
import { Container, Grid, Divider, Box, Typography} from "@material-ui/core";
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage';
// import { StaticImage } from "gatsby-plugin-image";
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';
import { StaticImage } from "gatsby-plugin-image";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(0.5,0),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));


function NewsSectionTemplate(props){

    // const classes = useStyles();

    const { data } = props;
    const { edges: posts } = data.allMarkdownRemark;

    // const [year, setYear] = useState('2023');

    const year = '2023';

    // const [filteredNews, setFilteredNews] = useState(posts);

    const filteredNews = posts;

    // const handleNewsYearChange = (e) => {
    //   console.log(e.target.value);
    //   setYear(e.target.value);
    //   setFilteredNews(posts.filter((post) => post.node.frontmatter.date.includes(e.target.value)));
    // }

    // const handleClearNewsFilter = () => {
    //   setYear('');
    //   setFilteredNews(posts);
    // }

    return (
      <Element name="news-section">
        <section className="news">
          <ScrollAnimation animateIn="fadeIn">
            <Container maxWidth="md">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className="sectionHeaderCenter">
                  <h2>{props.heading}</h2>
                  <Divider />
                </Grid>
                {/* <Grid item xs={12}>
                  <Typography className="newsFilterLabel">Filter by Year {year && <span role = "button" tabIndex={0}news onKeyDown={handleClearNewsFilter} onClick={handleClearNewsFilter} className="clearNewsFilter">RESET</span>}</Typography>
                  <FormControl className={classes.formControl} variant="filled">
                    <Select
                      labelId="news-filter-select-label"
                      id="news-filter-select"
                      className="newsFilter"
                      value={year && year}
                      onChange={handleNewsYearChange}
                    >
                      <MenuItem value={2023}>2023</MenuItem>
                      <MenuItem value={2022}>2022</MenuItem>
                      <MenuItem value={2021}>2021</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
              </Grid>
              {posts && (
              <>
                <Grid container spacing={2} style={{marginTop:"20px"}}>
                  {filteredNews.length > 0 && (
                    <>
                    {filteredNews.map(({ node: post }) => (
                      <>
                        <Grid item xs={12} sm={6} className="news-item" key={post.id}>
                          <Link to={post.fields.slug}>
                            {post.frontmatter.featuredimage ? (
                                <PreviewCompatibleImage
                                  imageInfo={{
                                    image: post.frontmatter.featuredimage,
                                    alt: `Featured image thumbnail for post ${post.frontmatter.title}`,
                                    
                                  }}
                                />
                            ) : 
                              <StaticImage src="../img/image-placeholder.jpg" alt=""  placeholder="blurred"/>
                            }
                            <Box className="newsDetailsContainer">
                              <h3 className="newsTitle">
                                {post.frontmatter.title}
                              </h3>
                              <div className="newsExcerpt">
                                {post.excerpt}
                              </div>
                              
                            </Box>
                            <Typography className="link">
                                Read More
                              </Typography>
                            <Divider className="divider" />
                          </Link>
                        </Grid>
                      </>
                  ))}
                  </>
                  )}
                  {filteredNews.length === 0 && (
                    <p>
                      There are no news items for {year}.
                    </p>
                  )}
                
                </Grid>
                {props.showViewAll && (
                  <Grid container>
                    <Grid item xs={12} align="center">
                      <Link to="/news">
                        <button className="primaryButton">
                          View All
                        </button>
                      </Link>
                    </Grid>
                  </Grid>
                )}
                </>
                )}
                {!posts && (
                  <Grid container spacing={2} style={{marginTop:"20px"}}>
                    <Grid item xs={12}>
                      <Typography className='bodyText' align="center">
                        There are no news items at present.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Container>
          </ScrollAnimation>
        </section>
      </Element>
    )
  }

NewsSection.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function NewsSection(props) {

  const {
    heading,
    showViewAll
  } = props;


  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "news-post" }, active: { eq: true } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  templateKey
                  active
                  date(formatString: "DD MMMM YYYY")
                  eventdate(formatString: "DD MMMM YYYY")
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <NewsSectionTemplate heading={heading} showViewAll={showViewAll} data={data} count={count} />}
    />
  );
}
